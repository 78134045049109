import { useEffect, useRef, FC } from 'react';
import JSONEditor, { JSONEditorOptions } from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.min.css';

interface Props {
  json: object;
  onChange: (json: string) => void;
}

export const JsonEditor: FC<Props> = ({ json, onChange }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<JSONEditor | null>(null);

  // Init JSON Editor
  useEffect(() => {
    if (containerRef.current) {
      const options: JSONEditorOptions = {
        mode: 'code',
        onChangeText: (jsonString: string) => {
          onChange(jsonString);
        },
      };

      editorRef.current = new JSONEditor(containerRef.current, options);
      editorRef.current.set(json);
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
      }
    };
  }, []);

  return <div ref={containerRef} style={{ height: '100%' }} />;
};
