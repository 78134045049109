import { useEffect } from "react";
import { ZAMMAD_FORM } from "../config";

const useZammadForm = (email: string) => {
  useEffect(() => {
    const loadScript = (src: string, id: string, callback?: () => void) => {
      if (document.getElementById(id)) return;
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = callback || null;
      document.body.appendChild(script);
    };

    loadScript('https://code.jquery.com/jquery-3.6.0.min.js', 'jquery', () => {
      loadScript(ZAMMAD_FORM, 'zammad_form_script', () => {
        (window as any).$(() => {
          (window as any).$('.show-zammad-form').ZammadForm({
            messageTitle: 'Support-Request',
            messageSubmit: 'Sent',
            messageThankYou: 'Thanks for your inquiry (#%s). We will contact you soon.',
            showTitle: true,
            modal: true,
            attachmentSupport: true,
            attributes: [
              {
                display: 'Name',
                name: 'name',
                tag: 'input',
                type: 'text',
                placeholder: 'Your Name',
                defaultValue: '',
              },
              {
                display: 'Email',
                name: 'email',
                tag: 'input',
                type: 'email',
                required: true,
                placeholder: 'Your Email',
                defaultValue: email,
              },
            ]
          });
        });
      });
    });
    // Cleanup function to remove the scripts when the component unmounts
    return () => {
      const jqueryScript = document.getElementById('jquery');
      const zammadScript = document.getElementById('zammad_form_script');
      if (jqueryScript) jqueryScript.remove();
      if (zammadScript) zammadScript.remove();
    };
  }, [email]);
};

export default useZammadForm;
