import { FunctionComponent, useState } from "react";
import {
  Typography,
  Button,
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import equal from 'fast-deep-equal';

import { JsonEditor } from './JsonEditor';
import { ConfirmModal } from "..";

type Props = {
  settings: any;
  handleSubmit: (settings: any) => void;
  onClose: () => void;
}

export const SettingsModal: FunctionComponent<Props> = ({ settings, onClose, handleSubmit }) => {
  // Keep 'settings' and 'jsonData' to compare them and show 'Save' button in case of difference
  const [jsonData, setJsonData] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleJsonChange = (jsonString: string) => {
    try {
      const jsonObj = JSON.parse(jsonString);
      setJsonData(jsonObj);
    } catch (error) {
      setJsonData(null);
    }
  }

  const handleSave = () => {
    handleSubmit(jsonData);
    onClose();
  }

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        onClose={onClose}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Settings
            </Typography>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => setIsConfirmOpen(true)}
              disabled={!jsonData || equal(jsonData, settings)} // Disable save if no changes
              sx={{ mr: 2 }}
            >
              Save
            </Button>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <JsonEditor
          json={settings}
          onChange={handleJsonChange}
        />
      </Dialog>
      <ConfirmModal
        isOpen={isConfirmOpen}
        text={`Are you sure you want to save these JSON settings?`}
        onSubmit={handleSave}
        onClose={() => setIsConfirmOpen(false)}
      />
    </>
  );
};
