import { FunctionComponent } from "react";
import {
  LoadScript,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "@react-google-maps/api";

import { MAP_CONTAINER_STYLE, MAP_OPTIONS } from "../../constants";
import { API_KEY } from "../../config";
import { Tooltip } from "..";
import {
  calculateCenter,
  calculatePath,
  getMarker,
  getLabelColor,
} from "../../utils";

type Props = {
  waypoints: Waypoint[];
  gpsTrack: GPSPoint[];
  onMarkerClick: (id: string) => void;
  activeMarker: string;
};

export const Map: FunctionComponent<Props> = ({
  waypoints,
  gpsTrack,
  onMarkerClick,
  activeMarker,
}) => {
  if (!waypoints?.length) return null;

  return (
    <LoadScript googleMapsApiKey={API_KEY}>
      <GoogleMap
        mapContainerStyle={MAP_CONTAINER_STYLE}
        options={MAP_OPTIONS}
        zoom={6}
        center={calculateCenter(waypoints)}
        onClick={() => onMarkerClick("")}
      >
        <Polyline
          path={calculatePath(waypoints)}
          options={{ strokeColor: "#185ce3" }}
        />
        {waypoints.map((point) => (
          <Marker
            key={point.id}
            position={point.coordinates}
            icon={getMarker(point.isAfqSet, point.isAtoSet)}
            label={{
              text: point.waypointName,
              className: `marker-label ${getLabelColor(
                point.isAfqSet,
                point.isAtoSet
              )}`,
            }}
            title={point.waypointName}
            onClick={() => onMarkerClick(point.id)}
          >
            {activeMarker === point.id ? (
              <InfoWindow onCloseClick={() => onMarkerClick("")}>
                <Tooltip {...point} />
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
        <Polyline path={gpsTrack.map((point) => ({ lat: point.latitude, lng: point.longitude }))} />
      </GoogleMap>
    </LoadScript>
  );
};
