import { FunctionComponent, useEffect } from "react";
import {
  Typography,
  Modal,
  Button,
  Backdrop,
  CircularProgress,
  Container,
  Paper,
  Box,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { usePutUser } from "../../hooks/useUser";
import { RED, GREEN } from "../../constants";

type Props = {
  user: User;
  isOpen: boolean;
  onClose: () => void;
}

export const EditUserModal: FunctionComponent<Props> = ({ user, isOpen, onClose }) => {
  const {
    setUser,
    email,
    onEmailChange,
    type,
    dbUserType,
    onTypeChange,
    password,
    onPasswordChange,
    tlc,
    pin,
    onPinChange,
    onTlcChange,
    onSubmit,
    message,
    error,
    status,
  } = usePutUser();

  useEffect(() => {
    setUser(user);
  }, [user]);

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby="Edit user"
      >
        <Container component="main" maxWidth="xs">
          <Paper
            sx={{
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 'calc(100% - 32px)',
              maxWidth: '536px',
            }}
          >
            <Box
              component="form"
              onSubmit={onSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              {!message && <>
                <Typography
                  component="h2"
                  variant="h4"
                  sx={{ pb: 3.5 }}
                >
                  Edit user
                </Typography>
                <div>
                  <FormControl sx={{ mr: 2, mb: 2, width: "100%", boxSizing: "content-box" }}>
                    <InputLabel>Type</InputLabel>
                    <Select value={type} onChange={onTypeChange} label="Type">
                      <MenuItem value={0}>smartOFP</MenuItem>
                      <MenuItem value={1}>FlightViewer</MenuItem>
                      <MenuItem value={2}>smartOFP + FlightViewer</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField value={email} error={!!error} label="Email" onChange={onEmailChange} sx={{ mb: 2, width: "100%", boxSizing: "content-box" }} />
                  {(type == 0 || type == 2) && <>
                    <TextField value={tlc} error={!!error} label="TLC" onChange={onTlcChange} sx={{ mr: 2, mb: 2, width: "100%", boxSizing: "content-box" }} />
                    <TextField value={pin} error={!!error} label={dbUserType !== 1 ? 'New PIN (optional)' : 'PIN'} onChange={onPinChange} sx={{ mb: 2, width: "100%", boxSizing: "content-box" }} />
                  </>}
                  {type > 0 && <TextField value={password} error={!!error} label={dbUserType !== 0 ? 'New password (optional)' : 'Password'} onChange={onPasswordChange} sx={{ mb: 2, width: "100%", maxWidth: "472px", boxSizing: "content-box" }} />}
                </div>
                <Typography component="p" color={RED}>
                  {error}
                </Typography>
              </>}
              {!!message && <Typography component="p" color={GREEN} sx={{ paddingBottom: "16px", fontSize: "20px" }}>
                {message}
              </Typography>}
              <Box sx={{ textAlign: "center" }}>
                {!message && <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ mb: 2, mr: 2, mt: 1.5, height: "56px", width: "calc(50% - 8px)" }}
                >
                  SUBMIT
                </Button>}
                <Button
                  size="large"
                  variant="outlined"
                  sx={{ mb: 2, mt: 1.5, height: "56px", width: "calc(50% - 8px)" }}
                  onClick={onClose}
                >Close</Button>
              </Box>
            </Box>
          </Paper>
          <Backdrop sx={{ zIndex: 1 }} open={status === "loading"}>
            <CircularProgress />
          </Backdrop>
        </Container>
      </Modal>
    </>
  );
};
