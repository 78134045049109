import { FunctionComponent } from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import { AdminUserForm, AdminUsersList } from "../components";
import { useOperators } from "../hooks/useUsers";

import { Page, WhiteBackground } from "../components";

const AdminUsers: FunctionComponent = () => {
  const { data, isLoading, isSuccess } = useOperators();

  return (
    <WhiteBackground>
      <Backdrop sx={{ zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </Backdrop>
      {!!isSuccess && (<Container>
        <Page>
          <AdminUserForm operators={data} />
          <AdminUsersList operators={data} />
        </Page>
      </Container>)}
    </WhiteBackground >
  );
};

export default AdminUsers;
