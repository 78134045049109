import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Dayjs } from 'dayjs';
import { API_URL } from "../config";

const fetchReport = async (id: string, date: Dayjs | null) => {
  const formattedDate = date ? date.format('YYYY-MM-DD HH:mm:ss') : null;
  const res = await fetch(`${API_URL}/library/report?userId=${id}&date=${formattedDate}`, {
    credentials: "include",
  });
  // TODO: process error
  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

const useLibraryReport = (id: string, date: Dayjs | null) => {
  const navigate = useNavigate();

  return useQuery(["libraryReport", id, date], () => fetchReport(id, date), {
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error: Error) => {
      if (error.message === "401") {
        localStorage.removeItem("user");
        localStorage.removeItem("isAuthenticated");
        navigate("/");
      }
    },
  });
};

export default useLibraryReport;
