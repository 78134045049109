import { FC, useState, useEffect, ChangeEvent } from "react";
import { useParams } from "react-router-dom";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Container, TextField, Grid, Backdrop, CircularProgress, Typography, Tooltip } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';

import { Page, WhiteBackground, Heading, ClearButton, DatePickerWrapper, DocumentsList } from "../components";
import { formatDateTime } from "../utils";
import useLibraryReport from "../hooks/useLibraryReport";

const LibraryReports: FC = () => {
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const { id } = useParams();
  const { data, isLoading, isSuccess, isError } = useLibraryReport(id || "", date);
  const [filter, setFilter] = useState("");
  const [filteredReadFiles, setFilteredReadFiles] = useState([]);
  const [filteredRemainingFiles, setFilteredRemainingFiles] = useState([]);
  const [filteredMissingFiles, setFilteredMissingFiles] = useState([]);

  useEffect(() => {
    if (!!data) {
      const filterText = filter.toLowerCase();
      setFilteredReadFiles(data?.read_files?.filter((file: ReportDocument) => file.url.toLowerCase().includes(filterText)));
      setFilteredRemainingFiles(data?.remaining_files?.filter((file: ReportDocument) => file.url.toLowerCase().includes(filterText)));
      setFilteredMissingFiles(data?.missing_files?.filter((file: ReportDocument) => file.url.toLowerCase().includes(filterText)));
    }
  }, [data, filter]);

  const onDateChange = (newDate: Dayjs | null) => {
    setDate(newDate);
  }

  const onFilterChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFilter(e.target.value);
  }

  const onClearDate = () => {
    setDate(dayjs());
  }

  return (
    <WhiteBackground>
      <Container>
        <Page>
          <Backdrop sx={{ zIndex: 1 }} open={isLoading}>
            <CircularProgress />
          </Backdrop>
          <Heading variant="h4" component="h1" sx={{ paddingBottom: "20px" }}>
            Library Report for {data?.tlc}
          </Heading>
          {isSuccess && <>
            <Grid container spacing={2}>
              <Grid item md={3} xs={6}>
                <TextField
                  value={filter}
                  onChange={onFilterChange}
                  label="Search Document"
                  sx={{ width: "100%", paddingBottom: "15px" }}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <DatePickerWrapper>
                  <DateTimePicker
                    value={date}
                    onChange={onDateChange}
                    format="YYYY-MM-DD HH:mm"
                    sx={{ width: "100%", paddingBottom: "15px" }}
                    label="Date"
                    minutesStep={1}
                  />
                  <ClearButton onClick={onClearDate} />
                </DatePickerWrapper>
              </Grid>
              <Grid item md={6} xs={12}>
              {!!data?.timestamp ? 
                <Tooltip placement="top-start" title="Most recent library update">
                  <Typography sx={{ cursor: "pointer", p: { md: "15px" } }}>Last update: {formatDateTime(new Date(data.timestamp))}</Typography>
                </Tooltip> : 
                <Typography sx={{ p: { md: "15px" } }}>Report not found for the selected date</Typography>
              }
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={4} sm={12}>
                <DocumentsList heading="Read Files" documents={filteredReadFiles} />
              </Grid>
              <Grid item md={4} sm={12}>
                <DocumentsList heading="Not Read Files" documents={filteredRemainingFiles} />
              </Grid>
              <Grid item md={4} sm={12}>
                <DocumentsList heading="Not Downloaded" documents={filteredMissingFiles} />
              </Grid>
            </Grid>
          </>}
          {isError && <Typography>Failed to load data</Typography>}
        </Page>
      </Container>
    </WhiteBackground>
  )
};

export default LibraryReports;
