import { FunctionComponent } from "react";
import { Container } from "@mui/material";
import { OperatorsList } from "../components";

import { Page, WhiteBackground } from "../components";

const Operators: FunctionComponent = () => (
  <WhiteBackground>
    <Container>
      <Page>
        <OperatorsList />
      </Page>
    </Container>
  </WhiteBackground>
);

export default Operators;
