import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";

export async function logoutUser() {
  const res = await fetch(`${API_URL}/auth/signout`, {
    method: "POST",
    credentials: "include",
  });

  const data = await res.json();
  if (!res.ok) {
    throw new Error(data.message || "Network response was not ok");
  }

  return data;
}

const useSignout = () => {
  const navigate = useNavigate();

  const mutation = useMutation(logoutUser);

  const handleSignout = () => {
    localStorage.clear();
    navigate("/");
    mutation.mutate();
  };

  return { handleSignout, status: mutation.status, error: mutation.error };
};

export default useSignout;
