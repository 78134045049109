import { FunctionComponent, useEffect } from "react";
import { Box, Typography, TextField, Button, Autocomplete, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useAddUser } from "../../hooks/useUser";
import { ConfirmModal } from "../ConfirmModal";
import { RED, GREEN } from "../../constants";

export const UserForm: FunctionComponent = () => {
  const {
    email,
    onEmailChange,
    type,
    onTypeChange,
    password,
    onPasswordChange,
    tlc,
    pin,
    onPinChange,
    onTlcChange,
    onOperatorChange,
    handleSubmit,
    handleCloseModal,
    message,
    error,
    handleConfirmAddUser,
    isModalOpen,
    status,
  } = useAddUser();

  useEffect(() => {
    const { operator_ref, operator_name } = JSON.parse(localStorage.getItem('user') || '{}');
    onOperatorChange(null, { id: operator_ref, label: operator_name });
  }, []);

  return (
    <>
      <ConfirmModal isOpen={isModalOpen} text={`Are you sure you want to add user ${email}?`} onSubmit={handleSubmit} onClose={handleCloseModal} isLoading={status === "loading"} />
      <Box
        component="form"
        onSubmit={handleConfirmAddUser}
        noValidate
        sx={{ mt: 1 }}
      >
        <Typography
          component="h2"
          variant="h4"
          sx={{ pb: 2 }}
        >
          Add new user
        </Typography>
        <Typography
          component="p"
          sx={{ pb: 2 }}
        >
          Users of smartOFP with an unset PIN will receive an email to reset their PIN for app access. Similarly, FlightViewer users with no password set will receive an email to reset their password for website access.
        </Typography>
        <FormControl sx={{ mr: 2, mb: 2, minWidth: "228px", boxSizing: "content-box" }}>
          <InputLabel>Type</InputLabel>
          <Select value={type} onChange={onTypeChange} label="Type">
            <MenuItem value={0}>smartOFP</MenuItem>
            <MenuItem value={1}>FlightViewer</MenuItem>
            <MenuItem value={2}>smartOFP + FlightViewer</MenuItem>
          </Select>
        </FormControl>
        <TextField value={email} error={!!error} label="Email" onChange={onEmailChange} sx={{ mr: 2, mb: 2, minWidth: "228px", boxSizing: "content-box" }} />
        {type > 0 && <TextField value={password} error={!!error} label="Password" onChange={onPasswordChange} sx={{ mr: 2, mb: 2, minWidth: "228px", boxSizing: "content-box" }} />}
        {(type == 0 || type == 2) && <TextField value={tlc} error={!!error} label="TLC" onChange={onTlcChange} sx={{ mr: 2, mb: 2, minWidth: "228px", boxSizing: "content-box" }} />}
        {(type == 0 || type == 2) && <TextField value={pin} error={!!error} label="PIN" onChange={onPinChange} sx={{ mr: 2, mb: 2, minWidth: "228px", boxSizing: "content-box" }} />}
        <Button
          type="submit"
          size="large"
          variant="contained"
          sx={{ mb: 1.5, height: "56px", minWidth: "228px" }}
        >
          SUBMIT
        </Button>
        <Typography component="p" color={RED}>
          {error}
        </Typography>
        <Typography component="p" color={GREEN}>
          {message}
        </Typography>
      </Box>
    </>
  );
};
