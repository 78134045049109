import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../utils";
import Signin from "../pages/Signin";

const Home: FunctionComponent = () => {
  if (isAuthenticated()) {
    return <Navigate to="/flights" replace />;
  }
  return <Signin />;
};

export default Home;
