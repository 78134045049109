import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#101010",
    },
    secondary: {
      main: "#006cf5",
    },
  },
});
