import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  List,
  Typography,
} from "@mui/material";
import { Folder } from "./Folder";
import { sortFiles } from "../../utils";
import useFiles from "../../hooks/useFiles";


export const FilesList: FunctionComponent = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess, isError } = useFiles(id || "");
  const { flightSummaryFiles, flightGeneratedPDFs, ppsFiles, otherFiles } = sortFiles(data);

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            paddingTop: "40px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {isError && (
        <Typography variant="h5" sx={{ paddingTop: "20px" }}>
          Files not found
        </Typography>
      )}
      {isSuccess && !!data.length && (
        <>
          <Typography variant="h5" sx={{ paddingTop: "20px" }}>
            Files:
          </Typography>
          <List>
            <Folder folderName="Flight Summary" flightId={id} files={flightSummaryFiles} />
            <Folder folderName="Flight Generated PDFs" flightId={id} files={flightGeneratedPDFs} />
            <Folder folderName="Camera Pictures/Shared Docs" flightId={id} files={otherFiles} />
            <Folder folderName="PPS" flightId={id} files={ppsFiles} />
          </List>
        </>
      )}
    </>
  );
};
