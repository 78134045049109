import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";

const fetchFlight = async (id: string) => {
  const res = await fetch(`${API_URL}/flights/${id}`, {
    credentials: "include",
  });
  // TODO: process error
  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

export const useGetFlight = (id: string) => {
  const navigate = useNavigate();

  return useQuery(["flight", id], () => fetchFlight(id), {
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error: Error) => {
      if (error.message === "401") {
        localStorage.removeItem("user");
        localStorage.removeItem("isAuthenticated");
        navigate("/");
      }
    },
  });
};

const deleteFlight = async (id: string) => {
  const res = await fetch(`${API_URL}/flights/${id}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

export const useDeleteFlight = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(deleteFlight, {
    onSuccess: () => {
      queryClient.invalidateQueries('flights');
      navigate('/');
    },
    onError: (error: Error) => {
      if (error.message === '401') {
        localStorage.removeItem('user');
        localStorage.removeItem('isAuthenticated');
        navigate('/');
      }
    },
  });
};

