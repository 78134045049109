import { FunctionComponent } from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";

import { Cell, HeadCell } from "..";
import { dateToHHMM } from "../../utils";

type Props = {
  fuelChecks: Waypoint[];
  onClick: (id: string) => any;
};

export const FuelChecksTable: FunctionComponent<Props> = ({
  fuelChecks,
  onClick,
}) => {
  if (!fuelChecks?.length) return null;

  return (
    <>
      <Typography
        variant="h5"
        sx={{ paddingTop: "16px", paddingBottom: "13px" }}
      >
        Fuel Checks:
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="fuel checks table">
          <TableHead>
            <TableRow>
              <HeadCell>Waypoint</HeadCell>
              <HeadCell>ETO</HeadCell>
              <HeadCell>ATO</HeadCell>
              <Tooltip placement="top-start" title="Min Fuel">
                <HeadCell>MFQ</HeadCell>
              </Tooltip>
              <Tooltip placement="top-start" title="Fuel On Board">
                <HeadCell>AFQ</HeadCell>
              </Tooltip>
              <Tooltip placement="top-start" title="Planned Fuel">
                <HeadCell>EFQ</HeadCell>
              </Tooltip>
              <Tooltip placement="top-start" title="Fuel On Board - Min Fuel">
                <HeadCell>AFQ&nbsp;-&nbsp;MFQ</HeadCell>
              </Tooltip>
              <Tooltip
                placement="top-start"
                title="Fuel On Board - Planned Fuel"
              >
                <HeadCell>AFQ&nbsp;-&nbsp;EFQ</HeadCell>
              </Tooltip>
            </TableRow>
          </TableHead>
          <TableBody>
            {fuelChecks.map((row: Waypoint) => (
              <TableRow key={row.id}>
                <Cell
                  component="th"
                  scope="row"
                  onClick={() => onClick(row.id)}
                  sx={{ cursor: "pointer" }}
                >
                  {row.waypointName}
                </Cell>
                <Cell>{dateToHHMM(row.ETO)}</Cell>
                <Cell>{row.ATO ? dateToHHMM(row.ATO) : "-"}</Cell>
                <Cell>{row.MFQ}</Cell>
                <Cell>{row.AFQ}</Cell>
                <Cell>{row.EFQ}</Cell>
                <Cell
                  sx={{
                    backgroundColor:
                      row.AFQ - row.MFQ > 0 ? "#00ff0029" : "#ff000029",
                  }}
                >
                  {row.AFQ - row.MFQ}
                </Cell>
                <Cell
                  sx={{
                    backgroundColor:
                      row.AFQ - row.EFQ > 0 ? "#00ff0029" : "#ff000029",
                  }}
                >
                  {row.AFQ - row.EFQ}
                </Cell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
