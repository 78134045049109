import { useQuery } from "react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDeleteFleet } from "../hooks/useFleet";
import { API_URL } from "../config";
import { EMPTY_FLEET } from "../constants";

const fetchFleets = async () => {
  const res = await fetch(`${API_URL}/fleets`, {
    credentials: "include",
  });
  // TODO: process error
  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

export const useFleets = () => {
  const [selectedFleet, setSelectedFleet] = useState(EMPTY_FLEET);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const navigate = useNavigate();

  const deleteMutation = useDeleteFleet();

  const handleOpenDeleteModal = (fleet: Fleet) => {
    if (fleet.id) {
      setSelectedFleet(fleet);
      setIsDeleteModalOpen(true);
    }
  }

  const handleDeleteFleet = () => {
    if (selectedFleet.id) {
      deleteMutation.mutate(selectedFleet.id);
      setIsDeleteModalOpen(false);
    }
  }

  const handleOpenEditModal = (fleet: Fleet) => {
    if (fleet.id) {
      setSelectedFleet(fleet);
      setIsEditModalOpen(true);
    }
  }

  const fleets = useQuery(["fleets"], () => fetchFleets(), {
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error: Error) => {
      if (error.message === "401") {
        localStorage.removeItem("user");
        localStorage.removeItem("isAuthenticated");
        navigate("/");
      }
    },
  })

  return {
    fleets,
    setIsDeleteModalOpen,
    handleOpenDeleteModal,
    isDeleteModalOpen,
    handleDeleteFleet,
    setIsEditModalOpen,
    handleOpenEditModal,
    isEditModalOpen,
    selectedFleet,
  };
};
