import { FunctionComponent } from "react";
import {
  Typography,
  Box,
  Button,
  Paper,
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { GREEN } from "../constants";

type Props = {
  title: string;
  signinButton: boolean;
};

export const SucessMessage: FunctionComponent<Props> = ({ title, signinButton }) => {
  return (
    <Paper
      sx={{
        padding: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Typography
        textAlign="center"
        component="h1"
        variant="h5"
        sx={{ fontSize: "1.8rem", padding: "10px 0 5px 0", color: GREEN }}
      >
        {title}
      </Typography>
      <Box
        component="div"
        sx={{ mt: 1 }}
      >
        {!!signinButton ?
          <Button
            href="/"
            fullWidth
            size="large"
            variant="contained"
            sx={{ mt: 2, mb: 2, height: "50px" }}
          >
            GO TO LOGIN PAGE
          </Button> : <CheckCircleOutlineIcon sx={{ mt: 1, mb: 1, fontSize: 60 }} />
        }
      </Box>
    </Paper>
  );
};
