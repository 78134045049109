import { useState, ChangeEvent, SyntheticEvent } from "react";
import { useMutation } from "react-query";
import { API_URL } from "../config";
import { logoutUser } from "./useSignout";

const resetPassword = async ({ password, token, title }: { password: string, token: string, title: 'Password' | 'PIN' }) => {
  const res = await fetch(`${API_URL}/users/reset/${title.toLowerCase()}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ password, token, pin: password }),
    credentials: "include",
  });

  if (!res.ok) {
    throw new Error(`The ${title.toLowerCase()} reset link is invalid. Please contact the administrator.`);
  }

  return res.json();
};

const useResetPassword = (token: string, title: 'Password' | 'PIN') => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");

  const mutation = useMutation(resetPassword, {
    onSuccess: () => {
      logoutUser();
      setError("");
      localStorage.clear();
    },
    onError: (err: { status: string; message: string }) => {
      setError(err.message);
    },
  });

  const onRepeatPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!password || !repeatPassword) {
      setError(`Please enter a new ${title.toLowerCase()} and confirm it`);
      return;
    }

    if (password.length < 4) {
      setError(`${title} must contain at least 4 characters`);
      return;
    }

    if (password !== repeatPassword) {
      setError(`You entered different ${title.toLowerCase()}s`);
      return;
    }

    mutation.mutate({ password, token, title });
  };

  return {
    repeatPassword,
    onRepeatPasswordChange,
    password,
    onPasswordChange,
    handleSubmit,
    status: mutation.status,
    error,
  };
};

export default useResetPassword;
