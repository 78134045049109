import { FunctionComponent, useEffect } from "react";
import {
  Typography,
  Modal,
  Button,
  Backdrop,
  CircularProgress,
  Container,
  Paper,
  Box,
  TextField,
} from "@mui/material";

import { AircraftSection } from "../aircraft";
import { useAddFleet } from "../../hooks/useFleet";
import { RED, GREEN } from "../../constants";

type Props = {
  onClose: () => void;
  allAircraft: Aircraft[];
}

export const AddFleetModal: FunctionComponent<Props> = ({ onClose, allAircraft }) => {
  const {
    name,
    onNameChange,
    onOperatorChange,
    addItemsToCart,
    leftItems,
    rightItems,
    onSubmit,
    message,
    error,
    status,
  } = useAddFleet(allAircraft, onClose);

  useEffect(() => {
    const { operator_ref, operator_name } = JSON.parse(localStorage.getItem('user') || '{}');
    onOperatorChange(null, { id: operator_ref, label: operator_name });
  }, []);

  return (
    <>
      <Modal
        open
        aria-labelledby="Add fleet"
      >
        <Container component="main" maxWidth="xs" sx={{
          display: "flex",
          justifyContent: "center"
        }}>
          <Paper
            sx={{
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              top: 20,
              width: "100%",
              maxWidth: 1200,
              maxHeight: "calc(100vh - 40px)",
              overflow: "auto",
            }}
          >
            <Box
              component="form"
              onSubmit={onSubmit}
              noValidate
              sx={{ width: "100%" }}
            >
              <Typography component="h2" variant="h4" sx={{ pb: 2 }}>
                Add fleet
              </Typography>
              <TextField value={name} error={!!error} label="Name" onChange={onNameChange} sx={{ mr: 2, width: "100%", maxWidth: "560px", boxSizing: "content-box" }} />
              <Box sx={{ pb: 1, pt: 1 }}>
                <Typography component="p" color={RED}>
                  {error}
                </Typography>
                <Typography component="p" color={GREEN}>
                  {message}
                </Typography>
              </Box>
              <AircraftSection addItemsToCart={addItemsToCart} leftItems={leftItems} rightItems={rightItems} />
              <Box sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ mb: 1, mr: 2, mt: 2, height: "56px", minWidth: "200px" }}
                >
                  SUBMIT
                </Button>
                <Button
                  size="large"
                  variant="outlined"
                  sx={{ mb: 1, mt: 2, height: "56px", minWidth: "200px" }}
                  onClick={onClose}
                >Close</Button>
              </Box>
            </Box>
          </Paper>
          <Backdrop sx={{ zIndex: 1 }} open={status === "loading"}>
            <CircularProgress />
          </Backdrop>
        </Container>
      </Modal>
    </>
  );
};
