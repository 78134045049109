import { FC } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Grid } from '@mui/material';
import { Cart } from "./Cart";
import { DragLayer } from "./DragLayer";

type Props = {
  leftItems: Aircraft[];
  rightItems: Aircraft[];
  addItemsToCart: (items: Aircraft[], source: string) => void;
};

export const AircraftSection: FC<Props> = ({ leftItems, rightItems, addItemsToCart }) => (
    <DndProvider backend={HTML5Backend}>
      <DragLayer />
      <Grid container spacing={2}>
        <Cart title="Fleet Aircraft" side="left" fields={leftItems} addItemsToCart={addItemsToCart} />
        <Cart title="All Aircraft"  side="right" fields={rightItems} addItemsToCart={addItemsToCart} />
      </Grid>
    </DndProvider>
  );
