import { useDragLayer } from "react-dnd";
import { Box, ListItem, Checkbox, ListItemText } from "@mui/material";

const getTransform = (currentOffset: any) => {
  if (!currentOffset) {
    return {
      display: "none"
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
};

const renderDragItems = (type: any, item: any) => {
  switch (type) {
    case "AIRCRAFT":
      return item.fields.map(({ id, registration, type }: Aircraft) => (
        <ListItem key={id} sx={{ padding: "6px 12px", opacity: 0.5, backgroundColor: "#eee", borderStyle: "dashed", mb: "1px" }}>
          <Checkbox checked />
          <ListItemText primary={`${registration} (${type})`} />
        </ListItem>))
    default:
      return null;
  }
};

export const DragLayer = () => {
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    })
  );

  if (!isDragging) {
    return null;
  }

  return (
    <Box sx={{
      position: "fixed",
      pointerEvents: "none",
      zIndex: 100,
      left: 0,
      top: 0,
      width: "100%",
      height: "100%"
    }}>
      <Box sx={getTransform(currentOffset)}>
        <Box sx={{ width: "100%", maxWidth: 560, opacity: isDragging ? 0.8 : 1 }}>
          {renderDragItems(itemType, item)}
        </Box>
      </Box>
    </Box>
  );
}
