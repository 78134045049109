import { FunctionComponent, useState } from "react";
import {
  Typography,
  Modal,
  Button,
  Backdrop,
  CircularProgress,
  Container,
  Paper,
} from "@mui/material";

type Props = {
  isOpen: boolean;
  text: string;
  isLoading?: boolean;
  onSubmit: () => void;
  onClose: () => void;
};

export const ConfirmModal: FunctionComponent<Props> = ({ isOpen, isLoading, text, onSubmit, onClose }) => {
  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby={text}
      >
        <Container component="main" maxWidth="xs">
          <Paper
            sx={{
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Typography id="modal-modal-title"
              component="h3"
              variant="h5"
              sx={{ mb: 3, textAlign: "center" }}
            >
              {text}
            </Typography>
            <div>
              <Button
                size="large"
                variant="contained"
                sx={{ mt: 1, mb: 2, mr: 3, height: "50px", width: "166px" }}
                onClick={onSubmit}
              >Confirm</Button>
              <Button
                size="large"
                variant="outlined"
                sx={{ mt: 1, mb: 2, height: "50px", width: "166px" }}
                onClick={onClose}
              >Cancel</Button>
            </div>
          </Paper>
          <Backdrop sx={{ zIndex: 1 }} open={isLoading || false}>
            <CircularProgress />
          </Backdrop>
        </Container>
      </Modal>
    </>
  );
};
