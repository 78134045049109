import { FunctionComponent, useState } from "react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {
  Button,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";

import { useFleets } from "../../hooks/useFleets";
import { ConfirmModal, EditFleetModal, AddFleetModal } from "..";

export const FleetsList: FunctionComponent = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const {
    fleets: { data, isLoading, isSuccess },
    setIsDeleteModalOpen,
    handleOpenDeleteModal,
    isDeleteModalOpen,
    handleDeleteFleet,
    setIsEditModalOpen,
    handleOpenEditModal,
    isEditModalOpen,
    selectedFleet,
  } = useFleets();

  if (isLoading) {
    return (
      <Backdrop sx={{ zIndex: 1 }} open={isLoading || false}>
        <CircularProgress />
      </Backdrop>
    )
  }

  return isSuccess && data?.length ? (
    <>
      <Typography
        component="h1"
        variant="h4"
        sx={{ pb: 3, pt: 1 }}
      >
        Manage fleets
      </Typography>
      <Button
        size="large"
        variant="contained"
        sx={{ mr: 2, mb: 5, height: "56px", minWidth: "220px" }}
        onClick={() => setIsAddModalOpen(true)}
      >
        Add Fleet
      </Button>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table aria-label="Fleets">
          <TableBody>
            {data?.slice(1).map((row: Fleet) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">
                  <Tooltip placement="top" title="Edit Fleet">
                    <BorderColorIcon fontSize="medium" sx={{ mr: 2, cursor: "pointer" }} onClick={() => handleOpenEditModal(row)} />
                  </Tooltip>
                  <Tooltip placement="top" title="Delete Fleet">
                    <DeleteForeverIcon fontSize="medium" sx={{ mr: 2, cursor: "pointer" }} onClick={() => handleOpenDeleteModal(row)} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        text={`Are you sure you want to delete fleet ${selectedFleet.name}?`}
        onSubmit={handleDeleteFleet}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <EditFleetModal
        isOpen={isEditModalOpen}
        fleet={selectedFleet}
        allAircraft={data[0].aircraft || []}
        onClose={() => setIsEditModalOpen(false)}
      />
      {isAddModalOpen && <AddFleetModal onClose={() => setIsAddModalOpen(false)} allAircraft={data[0].aircraft || []} />}
    </>
  ) : <Typography>No fleets found</Typography>;
};
