import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";

const fetchFlights = async (params: string) => {
  const res = await fetch(`${API_URL}/flights${params}`, {
    credentials: "include",
  });
  // TODO: process error
  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

const useFlights = (params: string) => {
  const navigate = useNavigate();

  return useQuery(["flights", params], () => fetchFlights(params), {
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error: Error) => {
      if (error.message === "401") {
        localStorage.removeItem("user");
        localStorage.removeItem("isAuthenticated");
        navigate("/");
      }
    },
  });
};

export default useFlights;
