import { FunctionComponent } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";

import { Cell, HeadCell, GrayRow } from "..";

export const FuelTable: FunctionComponent<Fuel & Weight> = ({
  trip_fuel_weight,
  trip_fuel_duration,
  altn,
  MINTOF_weight,
  MINTOF_duration,
  FRF_weight,
  FRF_duration,
  add_fuel_weight,
  add_fuel_duration,
  actual_onblock_fuel,
  indicated_offblock_fuel,
  contingency_fuel_weight,
  contingency_fuel_duration,
  planners_take_off_fuel_weight,
  planners_take_off_fuel_duration,
  taxi_fuel_weight,
  taxi_fuel_duration,
  extra_fuel_weight,
  extra_fuel_duration,
  DISCFUEL_weight,
  APUFUEL_weight,
  ZFM,
  TOM,
  LM,
}) => {
  const PLNTOF_weight = MINTOF_weight + contingency_fuel_weight;
  const PLNTOF_duration = MINTOF_duration + contingency_fuel_duration;
  const MINBLK_weight = PLNTOF_weight + taxi_fuel_weight;
  const MINBLK_duration = PLNTOF_duration + taxi_fuel_duration;
  const BLK_weight = MINBLK_weight + extra_fuel_weight + DISCFUEL_weight + APUFUEL_weight;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="fuel table">
        <TableHead>
          <TableRow>
            <HeadCell>
              <b>Fuel</b>
            </HeadCell>
            <HeadCell>Weight</HeadCell>
            <HeadCell>Duration</HeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <Cell component="th" scope="row">
              TRIP:
            </Cell>
            <Cell>{trip_fuel_weight}</Cell>
            <Cell>{trip_fuel_duration}</Cell>
          </TableRow>

          {altn.map((altnFuel: AltnFuel) => (
            <TableRow key={altnFuel.airport_icao}>
              <Cell component="th" scope="row">
                {MINTOF_weight - trip_fuel_weight - FRF_weight == altnFuel.weight ? 
                <b>ALTN{altnFuel.sequence}: {altnFuel.airport_icao}</b> : 
                <>ALTN{altnFuel.sequence}: {altnFuel.airport_icao}</>}
              </Cell>
              <Cell>{altnFuel.weight}</Cell>
              <Cell>{altnFuel.duration}</Cell>
            </TableRow>
          ))}

          <TableRow>
            <Cell component="th" scope="row">
              FRF:
            </Cell>
            <Cell>{FRF_weight}</Cell>
            <Cell>{FRF_duration}</Cell>
          </TableRow>

          <TableRow>
            <Cell component="th" scope="row">
              ADDF:
            </Cell>
            <Cell>{add_fuel_weight}</Cell>
            <Cell>{add_fuel_duration}</Cell>
          </TableRow>

          <GrayRow>
            <Cell component="th" scope="row">
              MINTOF:
            </Cell>
            <Cell>{MINTOF_weight}</Cell>
            <Cell>{MINTOF_duration}</Cell>
          </GrayRow>

          <TableRow>
            <Cell component="th" scope="row">
              CONT:
            </Cell>
            <Cell>{contingency_fuel_weight}</Cell>
            <Cell>{contingency_fuel_duration}</Cell>
          </TableRow>

          <GrayRow>
            <Cell component="th" scope="row">
              PLNTOF:
            </Cell>
            <Cell>{planners_take_off_fuel_weight}</Cell>
            <Cell>{planners_take_off_fuel_duration}</Cell>
          </GrayRow>

          <TableRow>
            <Cell component="th" scope="row">
              TAXI:
            </Cell>
            <Cell>{taxi_fuel_weight}</Cell>
            <Cell>{taxi_fuel_duration}</Cell>
          </TableRow>

          <GrayRow>
            <Cell component="th" scope="row">
              MINBLKF:
            </Cell>
            <Cell>{MINBLK_weight}</Cell>
            <Cell>{MINBLK_duration}</Cell>
          </GrayRow>

          <TableRow>
            <Cell component="th" scope="row">
              EXTRA:
            </Cell>
            <Cell>{extra_fuel_weight}</Cell>
            <Cell>{extra_fuel_duration}</Cell>
          </TableRow>

          <TableRow>
            <Cell component="th" scope="row">
              DISCF:
            </Cell>
            <Cell>{DISCFUEL_weight}</Cell>
            <Cell />
          </TableRow>

          <TableRow>
            <Cell component="th" scope="row">
              APU:
            </Cell>
            <Cell>{APUFUEL_weight}</Cell>
            <Cell />
          </TableRow>

          <GrayRow>
            <Cell component="th" scope="row">
              BLKF:
            </Cell>
            <Cell>{BLK_weight}</Cell>
            <Cell />
          </GrayRow>

          {indicated_offblock_fuel && <GrayRow>
            <Cell component="th" scope="row">
              Indicated BLKF:
            </Cell>
            <Cell>{indicated_offblock_fuel}</Cell>
            <Cell />
          </GrayRow>}

          <TableRow>
            <Cell component="th" scope="row">
              ONBLKF:
            </Cell>
            <Cell>{actual_onblock_fuel}</Cell>
            <Cell />
          </TableRow>

          <TableRow>
            <HeadCell>ZFM: {ZFM}</HeadCell>
            <HeadCell>TOM: {TOM}</HeadCell>
            <HeadCell>LM: {LM}</HeadCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
};
