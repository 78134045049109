import { FunctionComponent, useState, useMemo } from "react";
import { Backdrop, CircularProgress, Container, Grid, Pagination } from "@mui/material";
import { Dayjs } from 'dayjs';

import { buildFilterQueryParams } from "../utils";
import { EMPTY_FILTERS, PAGINATION_LIMIT } from "../constants";
import { Page, Heading, Filters, FlightsList } from "../components";
import useFlights from "../hooks/useFlights";

const Flights: FunctionComponent = () => {
  const [filters, setFilters] = useState(EMPTY_FILTERS);
  const [page, setPage] = useState(1);
  const queryParams = useMemo(() => buildFilterQueryParams(filters, page), [filters, page]);

  const { data, isLoading, isSuccess } = useFlights(queryParams);

  const handleDateChange = (date: Dayjs | null) => {
    setPage(1);
    setFilters({ ...EMPTY_FILTERS, date });
  };

  const handleCallsignChange = (_: any, callsign: string | null) => {
    setPage(1);
    setFilters({ ...filters, callsign });
  };

  const handleRegistrationChange = (_: any, registration: string | null) => {
    setPage(1);
    setFilters({ ...filters, registration });
  };

  const handleDepartureAirportChange = (
    _: any,
    departureAirport: string | null
  ) => {
    setPage(1);
    setFilters({ ...filters, departureAirport });
  };

  const handleArrivalAirportChange = (
    _: any,
    arrivalAirport: string | null
  ) => {
    setPage(1);
    setFilters({ ...filters, arrivalAirport });
  };

  const handlePageChange = (_: any, value: number) => {
    if (value !== page) {
      setPage(value);
    }
  };

  return (
    <Container>
      <Page>
        <Backdrop sx={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress />
        </Backdrop>
        <>
          <Heading variant="h3" component="h1">
            Search for flights
          </Heading>
          <Grid container spacing={{ sm: 4, xs: 0 }}>
            <Grid item sm={4} sx={{ width: "100%" }}>
              <Filters
                options={data?.filters || {}}
                filters={filters}
                onDateChange={handleDateChange}
                onCallsignChange={handleCallsignChange}
                onRegistrationChange={handleRegistrationChange}
                onDepartureAirportChange={handleDepartureAirportChange}
                onArrivalAirportChange={handleArrivalAirportChange}
              />
            </Grid>
            <Grid item sm={8}>
              {isSuccess && <FlightsList items={data.flights} />}
              {data?.totalCount > PAGINATION_LIMIT && <Pagination count={Math.ceil(data?.totalCount / PAGINATION_LIMIT) || 1} page={page} onChange={handlePageChange} shape="rounded" />}
            </Grid>
          </Grid>
        </>
      </Page>
    </Container>
  );
};

export default Flights;
