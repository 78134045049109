import { useMutation, useQueryClient, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useState, ChangeEvent } from "react";
import { API_URL } from "../config";
import { EMPTY_OPERATOR } from "../constants";
import { validateEmail } from "../utils";

// const deleteUser = async (id: string) => {
//   const res = await fetch(`${API_URL}/users/${id}`, {
//     method: 'DELETE',
//     credentials: 'include',
//   });

//   if (!res.ok) {
//     throw new Error(`${res.status}`);
//   }

//   return res.json();
// };

// export const useDeleteUser = () => {
//   const navigate = useNavigate();
//   const queryClient = useQueryClient();

//   return useMutation(deleteUser, {
//     onSuccess: () => {
//       queryClient.invalidateQueries('users');
//     },
//     onError: (error: Error) => {
//       if (error.message === '401') {
//         localStorage.removeItem('user');
//         localStorage.removeItem('isAuthenticated');
//         navigate('/');
//       }
//     },
//   });
// };

const postOperator = async (operator: Operator) => {
  const res = await fetch(`${API_URL}/operators`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(operator),
    credentials: "include",
  });

  if (!res.ok) {
    throw { message: res.statusText, code: res.status };
  }

  return res.json();
};

export const usePostOperator = () => {
  const [operator, setOperator] = useState(EMPTY_OPERATOR);
  const [error, setError] = useState("");
  const [message, setMesssage] = useState("");

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(postOperator, {
    onSuccess: (resOperator: Operator) => {
      queryClient.invalidateQueries('operators');
      setOperator(resOperator);
      setError("");
      setMesssage(`Operator ${operator.name} has been added!`);
    },
    onError: (err: HttpError) => {
      if (err.code === 401) {
        localStorage.removeItem('user');
        localStorage.removeItem('isAuthenticated');
        navigate('/');
      }

      setError("You can't add this operator");
      setMesssage("");
    },
  });

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, name: e.target.value});
    setError("");
    setMesssage("");
  };

  const handleCompanyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, company: e.target.value });
    setError("");
    setMesssage("");
  };

  const handleIcaoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, icao_code: e.target.value });
    setError("");
    setMesssage("");
  };


  const handleContactChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, contact_name: e.target.value });
    setError("");
    setMesssage("");
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, email: e.target.value });
    setError("");
    setMesssage("");
  };

  const handleTelChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, tel_no: e.target.value });
    setError("");
    setMesssage("");
  };

  const handleDashboardEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, is_dashboard_enabled: e.target.checked });
    setError("");
    setMesssage("");
  };

  const onSubmit = () => {
    setError("");
    setMesssage("");
    if (!operator.name?.length) {
      setError("Please enter a valid name");
      return;
    }
    if (!operator.company?.length) {
      setError("Please enter a valid company");
      return;
    }
    if (operator.icao_code?.length !== 3) {
      setError("Please enter a valid ICAO");
      return;
    }
    if (!!operator.email && !validateEmail(operator.email)) {
      setError("Please enter a valid email address");
      return;
    }
    if (!!operator.tel_no && operator.tel_no?.length < 8) {
      setError("Please enter a valid phone number");
      return;
    }
    mutation.mutate(operator);
  }

  const onSettingsSubmit = (settings: any) => {
    console.log()
    setOperator({ ...operator, settings })
  }

  return {
    operator,
    setOperator,
    handleNameChange,
    handleCompanyChange,
    handleIcaoChange,
    handleContactChange,
    handleEmailChange,
    handleTelChange,
    handleDashboardEnabledChange,
    onSubmit,
    onSettingsSubmit,
    isLoading: mutation.isLoading,
    error,
    message,
  };
};

const putOperator = async (operator: Operator) => {
  const res = await fetch(`${API_URL}/operators/${operator.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(operator),
    credentials: "include",
  });

  if (!res.ok) {
    throw { message: res.statusText, code: res.status };
  }

  return res.json();
};

export const usePutOperator = (selectedOperator: Operator) => {
  const [operator, setOperator] = useState(selectedOperator);
  const [error, setError] = useState("");
  const [message, setMesssage] = useState("");

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(putOperator, {
    onSuccess: (resOperator: Operator) => {
      queryClient.invalidateQueries('operators');
      // Update initial Settings
      setOperator(resOperator);
      setError("");
      setMesssage(`Operator ${operator.name} has been updated!`);
    },
    onError: (err: HttpError) => {
      if (err.code === 401) {
        localStorage.removeItem('user');
        localStorage.removeItem('isAuthenticated');
        navigate('/');
      }

      setError("You can't update this operator");
      setMesssage("");
    },
  });

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, name: e.target.value});
    setError("");
    setMesssage("");
  };

  const handleCompanyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, company: e.target.value });
    setError("");
    setMesssage("");
  };

  const handleIcaoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, icao_code: e.target.value });
    setError("");
    setMesssage("");
  };


  const handleContactChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, contact_name: e.target.value });
    setError("");
    setMesssage("");
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, email: e.target.value });
    setError("");
    setMesssage("");
  };

  const handleTelChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, tel_no: e.target.value });
    setError("");
    setMesssage("");
  };

  const handleDashboardEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, is_dashboard_enabled: e.target.checked });
    setError("");
    setMesssage("");
  };

  const onSubmit = () => {
    setError("");
    setMesssage("");
    if (!operator.name?.length) {
      setError("Please enter a valid name");
      return;
    }
    if (!operator.company?.length) {
      setError("Please enter a valid company");
      return;
    }
    if (operator.icao_code?.length !== 3) {
      setError("Please enter a valid ICAO");
      return;
    }
    if (!!operator.email && !validateEmail(operator.email)) {
      setError("Please enter a valid email address");
      return;
    }
    if (!!operator.tel_no && operator.tel_no?.length < 8) {
      setError("Please enter a valid phone number");
      return;
    }
    mutation.mutate(operator);
  }

  const onSettingsSubmit = (settings: any) => {
    mutation.mutate({
      ...operator,
      settings,
    });
  }

  return {
    operator,
    setOperator,
    handleNameChange,
    handleCompanyChange,
    handleIcaoChange,
    handleContactChange,
    handleEmailChange,
    handleTelChange,
    handleDashboardEnabledChange,
    onSubmit,
    onSettingsSubmit,
    isLoading: mutation.isLoading,
    error,
    message,
  };
};

const fetchOperators = async () => {
  const res = await fetch(`${API_URL}/operators`, {
    credentials: "include",
  });
  // TODO: process error
  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

export const useOperators = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState(EMPTY_OPERATOR);
  // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();

  // const deleteMutation = useDeleteOperator();

  // const handleOpenDeleteModal = (operator: Operator) => {
  //   if (operator.id) {
  //     setSelectedOperator(operator);
  //     setIsDeleteModalOpen(true);
  //   }
  // }

  // const handleDeleteOperator = () => {
  //   if (selectedOperator.id) {
  //     deleteMutation.mutate(selectedOperator.id);
  //     setIsDeleteModalOpen(false);
  //   }
  // }

  const handleOpenEditModal = (operator: Operator) => {
    if (operator.id) {
      setSelectedOperator(operator);
      setIsEditModalOpen(true);
    }
  }

  const operators = useQuery(["operators"], () => fetchOperators(), {
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error: Error) => {
      if (error.message === "401") {
        localStorage.removeItem("user");
        localStorage.removeItem("isAuthenticated");
        navigate("/");
      }
    },
  })

  return {
    operators,
    setIsAddModalOpen,
    isAddModalOpen,
    setIsEditModalOpen,
    handleOpenEditModal,
    isEditModalOpen,
    selectedOperator,
    // setIsDeleteModalOpen,
    // handleOpenDeleteModal,
    // isDeleteModalOpen,
    // handleDeleteOperator,
  };
};
