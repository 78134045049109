import { FunctionComponent } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Backdrop,
  Paper,
  CircularProgress,
} from "@mui/material";

import { StyledContainer, SucessMessage } from "../components";
import { RED } from "../constants";
import useResetPassword from "../hooks/useResetPassword";

const ResetPassword: FunctionComponent = () => {
  const { token } = useParams();
  const isResetPasswordPage = useLocation().pathname.includes("reset-password");
  const title = isResetPasswordPage ? "Password" : "PIN";

  const {
    password,
    onPasswordChange,
    repeatPassword,
    onRepeatPasswordChange,
    handleSubmit,
    status,
    error,
  } = useResetPassword(token || "", title);

  return (
    <>
      <StyledContainer maxWidth={false}>
        <Backdrop sx={{ zIndex: 1 }} open={status === "loading"}>
          <CircularProgress />
        </Backdrop>
        <Container component="main" maxWidth="xs">
          {status === "success" ? <SucessMessage title={`The ${title} has been successfully changed!`} signinButton={isResetPasswordPage} /> :
            <Paper
              sx={{
                padding: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                sx={{ fontSize: "1.8rem", padding: "10px 0 5px 0" }}
              >
                RESET {title.toLocaleUpperCase()}
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  error={!!error}
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label={`Enter new ${title.toLowerCase()}`}
                  type="password"
                  name="password"
                  autoComplete="off"
                  autoFocus
                  value={password}
                  onChange={onPasswordChange}
                />
                <TextField
                  error={!!error}
                  margin="normal"
                  required
                  fullWidth
                  name="repeatPassword"
                  label={`Сonfirm new ${title.toLowerCase()}`}
                  type="password"
                  id="repeatPassword"
                  autoComplete="off"
                  value={repeatPassword}
                  onChange={onRepeatPasswordChange}
                />
                <Typography component="p" color={RED}>
                  {error}
                </Typography>
                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, height: "50px" }}
                >
                  SUBMIT
                </Button>
              </Box>
            </Paper>}
        </Container>
      </StyledContainer>
    </>
  );
};

export default ResetPassword;
