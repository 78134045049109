import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { Container, Backdrop, CircularProgress, Box } from "@mui/material";
import { WhiteBackground, Page } from "../components";
import { useDashboardFlight } from "../hooks/useDashboard";

const DashboardFlight: FunctionComponent = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useDashboardFlight(id || "");

  return (
    <WhiteBackground>
      <Container>
        <Page>
          <Backdrop sx={{ zIndex: 1 }} open={isLoading}>
            <CircularProgress />
          </Backdrop>
          {!!isSuccess && data?.url && <Box sx={{ height: 1456, overflow: "hidden" }}>
            <iframe
              src={`${data.url}#bordered=false&titled=false`}
              width="100%"
              height={2000}
            />
          </Box>}
        </Page>
      </Container>
    </WhiteBackground >
  )
};

export default DashboardFlight;
