import { FunctionComponent } from "react";
import { Clear } from ".";

type Props = {
  onClick: () => void;
};

export const ClearButton: FunctionComponent<Props> = ({ onClick }) => (
  <Clear onClick={onClick} >
    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" fill="#5f5f5f">
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
      </path>
    </svg>
  </Clear>
);
