import { useEffect, useState, FC, ChangeEvent } from "react";
import { useDrop } from "react-dnd";
import { Checkbox, Grid, List, TextField, Box, FormControlLabel, Typography, Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Item } from "./Item";

type Props = {
  title: string;
  side: string;
  fields: Aircraft[];
  addItemsToCart: (items: Aircraft[], source: string) => void;
};

export const Cart: FC<Props> = ({ fields, side, addItemsToCart, title }) => {
  const [, drop] = useDrop(() => ({
    accept: "AIRCRAFT",
    canDrop: (item: { source: string }) => item.source !== side,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [selectedFields, setSelectedFields] = useState<Aircraft[]>([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);
  const [selectAll, setSelectAll] = useState(false);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    if (selectAll) {
      setSelectedFields(fields.filter(field => field.registration?.toLowerCase().includes(filterText.toLowerCase())));
    }
  }, [selectAll, fields, filterText]);

  const clearItemSelection = () => {
    setSelectedFields([]);
    setLastSelectedIndex(-1);
    setSelectAll(false);
  };

  useEffect(() => {
    clearItemSelection();
  }, [fields, side]);

  const handleItemSelection = (index: number, cmdKey: boolean, shiftKey: boolean) => {
    const selectedField = fields[index];
    const isSelected = selectedFields.some(({ id }) => id === selectedField.id);

    if (!cmdKey && !shiftKey) {
      setSelectedFields(isSelected
        ? selectedFields.filter(({ id }) => id !== selectedField.id)
        : [selectedField]);
    }
    else if (shiftKey && lastSelectedIndex !== -1) {
      const range = [Math.min(lastSelectedIndex, index), Math.max(lastSelectedIndex, index)];
      const newSelection = fields.filter((_, idx) => idx >= range[0] && idx <= range[1]);
      const updatedSelection = new Set([...selectedFields, ...newSelection]);
      setSelectedFields(Array.from(updatedSelection));
    }
    else {
      const updatedFields = isSelected
        ? selectedFields.filter(({ id }) => id !== selectedField.id)
        : [...selectedFields, selectedField];
      setSelectedFields(updatedFields);
    }

    setLastSelectedIndex(index);
  };

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
    if (event.target.value) {
      clearItemSelection();
    }
  };

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedFields(fields.filter(field => field.registration?.toLowerCase().includes(filterText.toLowerCase())));
    } else {
      setSelectedFields([]);
    }
  };

  const handleMoveSelected = () => {
    addItemsToCart(selectedFields, side);
    clearItemSelection();
  };

  const filteredFields = fields.filter(field => field.registration?.toLowerCase().includes(filterText.toLowerCase()));

  return (
    <Grid ref={drop} item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
      <Typography component="h2" variant="h5" sx={{ pb: 1, bt: 1 }}>{title}</Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FormControlLabel
          control={<Checkbox checked={selectAll} onChange={handleSelectAllChange} color="primary" />}
          label="Select All"
          onClick={(event) => event.stopPropagation()}
        />
        <TextField
          margin="normal"
          label="Filter"
          variant="outlined"
          onChange={handleFilterChange}
          value={filterText}
          sx={{ mb: 2, flexGrow: 1}}
        />
        <Button
          variant="contained"
          sx={{ ml: 2, height: "56px" }}
          onClick={handleMoveSelected}
          endIcon={side === "left" ? <DeleteIcon /> : <AddIcon />}
        >{side === "left" ? "Remove" : "Add"}</Button>
      </Box>
      <List sx={{
          border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: "4px",
          maxHeight: "calc(100vh - 442px)",
          minHeight: 100,
          overflow: "auto",
          flexGrow: 1,
        }}>
        {filteredFields.map((field, index) => (
          <Item
            key={field.id}
            id={field.id}
            registration={field.registration}
            type={field.type}
            selectedSource={side}
            addItemsToCart={addItemsToCart}
            clearItemSelection={clearItemSelection}
            selectedFields={selectedFields}
            handleSelection={handleItemSelection}
            index={index}
          />
        ))}
      </List>
    </Grid>
  );
};
