import { useState, ChangeEvent, SyntheticEvent } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";

const signin = async (credentials: { email: string; password: string }) => {
  const res = await fetch(`${API_URL}/auth/signin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
    credentials: "include",
  });

  if (!res.ok) {
    throw new Error("Invalid credentials");
  }

  return res.json();
};

const useSignin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const mutation = useMutation(signin, {
    onSuccess: (data) => {
      setError("");
      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem("isAuthenticated", "true");
      navigate("/flights");
    },
    onError: (err: { status: string; message: string }) => {
      setError(err.message);
    },
  });

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    mutation.mutate({ email, password });
  };

  return {
    email,
    onEmailChange,
    password,
    onPasswordChange,
    handleSubmit,
    status: mutation.status,
    error,
  };
};

export default useSignin;
