import { useEffect, FC } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDrag } from "react-dnd";
import { ListItem, ListItemText, Checkbox, ListItemButton } from '@mui/material';

type Props = {
  id?: number;
  registration?: string;
  type?: string;
  selectedSource: string;
  addItemsToCart: (items: Aircraft[], source: string) => void;
  clearItemSelection: () => void;
  selectedFields: Aircraft[];
  handleSelection: (index: number, cmdKey: boolean, shiftKey: boolean) => void;
  index: number;
};

export const Item:FC<Props> = ({ id, registration, type, selectedSource, addItemsToCart, clearItemSelection, selectedFields, handleSelection, index }) => {
  const [ {isDragging }, drag, preview] = useDrag({
    item: () => {
      const dragFields = selectedFields.some(field => field.id === id)
        ? selectedFields
        : [...selectedFields, { registration, type, id }];
      return { fields: dragFields, source: selectedSource, name: name };
    },
    type: "AIRCRAFT",
    end: (item: any, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        addItemsToCart(item.fields, item.source);
        clearItemSelection();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const handleRowSelection = (cmdKey: boolean, shiftKey: boolean, index: number) => {
    handleSelection(index, cmdKey, shiftKey);
  };

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true
    });
  }, []);

  const selected = !!selectedFields.find((field: Aircraft) => id === field.id);
  return (
    <ListItem ref={drag} sx={{ padding: 0, opacity: isDragging ? 0.5 : 1, backgroundColor: !!selected ? "#eee" : "#fff" }}>
      <ListItemButton onClick={(e) => handleRowSelection(e.metaKey, e.shiftKey, index)}>
        <Checkbox checked={selected} />
        <ListItemText primary={`${registration} (${type})`} />
      </ListItemButton>
    </ListItem>
  );
}
