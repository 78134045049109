import { FunctionComponent } from "react";
import {
  Typography,
  Modal,
  Button,
  Backdrop,
  CircularProgress,
  Container,
  Paper,
} from "@mui/material";
import { useDeleteFlight } from "../../hooks/useFlight";

type Props = {
  id: string;
  onClose: () => void;
};

export const DeleteFlightModal: FunctionComponent<Props> = ({ id, onClose }) => {
  const deleteMutation = useDeleteFlight();

  const handleDelete = () => {
    deleteMutation.mutate(id);
    onClose();
  };

  return (
    <>
      <Backdrop sx={{ zIndex: 1 }} open={deleteMutation.isLoading}>
        <CircularProgress />
      </Backdrop>
      <Modal
        open={id !== ''}
        onClose={onClose}
        aria-labelledby={`Delete flight ${id}`}
      >
        <Container component="main" maxWidth="xs">
          <Paper
            sx={{
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Typography id="modal-modal-title"
              component="h3"
              variant="h5"
              sx={{ mb: 3 }}
            >
              Are you sure you want to delete Flight {id}?
            </Typography>
            <div>
              <Button
                size="large"
                variant="contained"
                sx={{ mt: 1, mb: 2, mr: 3, height: "50px", width: "166px" }}
                onClick={handleDelete}
              >Delete Flight</Button>
              <Button
                size="large"
                variant="outlined"
                sx={{ mt: 1, mb: 2, height: "50px", width: "166px" }}
                onClick={onClose}
              >Cancel</Button>
            </div>
          </Paper>
        </Container>
      </Modal>
    </>
  );
};
