import { FunctionComponent } from "react";
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
  Button,
  CircularProgress,
} from "@mui/material";

import { useOperators } from "../../hooks/useOperators";
import { EditOperatorModal, AddOperatorModal, HeadCell } from "..";

export const OperatorsList: FunctionComponent = () => {
  const {
    operators: { data, isLoading, isSuccess },
    setIsAddModalOpen,
    isAddModalOpen,
    setIsEditModalOpen,
    handleOpenEditModal,
    isEditModalOpen,
    selectedOperator,
    // setIsDeleteModalOpen,
    // handleOpenDeleteModal,
    // isDeleteModalOpen,
    // handleDeleteOperator,
  } = useOperators();

  if (isLoading) {
    return (
      <Backdrop sx={{ zIndex: 1 }} open={isLoading || false}>
        <CircularProgress />
      </Backdrop>
    )
  }

  return (
    <>
      <Typography
        component="h1"
        variant="h4"
        sx={{ pb: 3, pt: 1 }}
      >
        Manage operators
      </Typography>
      <Button
        size="large"
        variant="contained"
        sx={{ mr: 2, mb: 5, height: "56px" }}
        onClick={() => setIsAddModalOpen(true)}
        startIcon={<AddIcon />}
      >
        Add Operator
      </Button>
      {isAddModalOpen && <AddOperatorModal isOpen={true} onClose={() => setIsAddModalOpen(false)} />}
      {!!isSuccess && !!data?.length && <>
        <TableContainer component={Paper} sx={{ mb: 2 }}>
          <Table aria-label="Operators">
            <TableHead>
              <TableRow>
                <HeadCell>Id</HeadCell>
                <HeadCell>Name</HeadCell>
                <HeadCell>Company</HeadCell>
                <HeadCell>ICAO</HeadCell>
                <HeadCell>Contact</HeadCell>
                <HeadCell>Email</HeadCell>
                <HeadCell>Phone</HeadCell>
                <HeadCell>Dashboard</HeadCell>
                <HeadCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.sort((a: Operator, b: Operator) => a.id - b.id).map((row: Operator) => (
                <TableRow
                  key={row.name}
                  sx={{
                    '&:nth-of-type(even)': { backgroundColor: '#00000008' },
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.company}</TableCell>
                  <TableCell>{row.icao_code}</TableCell>
                  <TableCell>{row.contact_name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.tel_no}</TableCell>
                  <TableCell>{row.is_dashboard_enabled ? <CheckCircleIcon /> : ""}</TableCell>
                  <TableCell align="right">
                    <Tooltip placement="top" title="Edit Operator">
                      <BorderColorIcon fontSize="medium" sx={{ mr: 2, cursor: "pointer" }} onClick={() => handleOpenEditModal(row)} />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isEditModalOpen && <EditOperatorModal
          isOpen={true}
          selectedOperator={selectedOperator}
          onClose={() => setIsEditModalOpen(false)}
        />}
      </>}
    </>)
};
