import { FunctionComponent } from "react";
import { Container } from "@mui/material";
import { FleetsList } from "../components";

import { Page, WhiteBackground } from "../components";

const Fleets: FunctionComponent = () => (
  <WhiteBackground>
    <Container>
      <Page>
        <FleetsList />
      </Page>
    </Container>
  </WhiteBackground >
);

export default Fleets;
