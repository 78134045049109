import { FunctionComponent, useState } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Collapse,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FolderIcon from '@mui/icons-material/Folder';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { API_URL } from "../../config";

type Props = {
  folderName: string;
  flightId?: string;
  files: string[];
};

export const Folder: FunctionComponent<Props> = ({ folderName, flightId, files }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick} disableGutters>
        <ListItemIcon sx={{ minWidth: "22px" }}>
          <FolderIcon
            sx={{
              fontSize: "1.1rem",
              color: "#000",
            }}
          />
        </ListItemIcon>
        <ListItemText primary={folderName} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Divider
        key={`divider-${folderName}`}
        variant="fullWidth"
        component="div"
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {files.map((file: string) => (
            <li key={`item-${file}`}>
              <ListItem
                sx={{ overflow: "hidden", paddingLeft: '8px', paddingRight: 0 }}
                target="_blank"
                href={`${API_URL}/flights/${flightId}/files/${file}`}
                alignItems="flex-start"
                component="a"
              >
                <ListItemIcon sx={{ minWidth: "22px" }}>
                  <DownloadIcon
                    sx={{
                      fontSize: "1.1rem",
                      color: "#000",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{ wordWrap: "break-word" }}
                  primary={file.substring(file.indexOf("_") + 1)}
                />
              </ListItem>
              <Divider
                key={`divider-${file}`}
                variant="fullWidth"
                component="div"
              />
            </li>
          ))}
        </List>
      </Collapse>
    </>
  );
};
