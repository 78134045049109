import { FunctionComponent, useEffect, useState, SyntheticEvent } from "react";
import {
  Typography,
  Modal,
  Button,
  Backdrop,
  CircularProgress,
  Container,
  Paper,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import Setings from "@mui/icons-material/Settings";

import { SettingsModal } from "./SettingsModal";
import { usePutOperator } from "../../hooks/useOperators";
import { RED, GREEN } from "../../constants";

type Props = {
  selectedOperator: Operator;
  isOpen: boolean;
  onClose: () => void;
}

export const EditOperatorModal: FunctionComponent<Props> = ({ selectedOperator, isOpen, onClose }) => {
  const {
    operator,
    handleNameChange,
    handleCompanyChange,
    handleIcaoChange,
    handleContactChange,
    handleEmailChange,
    handleTelChange,
    handleDashboardEnabledChange,
    onSubmit,
    onSettingsSubmit,
    message,
    error,
    isLoading,
  } = usePutOperator(selectedOperator);

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleSettingsOpen = () => {
    setIsSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setIsSettingsOpen(false);
  };

  const handleOperatorSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  }

  return (
    <Modal
      open={isOpen}
      aria-labelledby="Edit operator settings"
    >
      <Container component="main" maxWidth="xs">
        <Paper
          sx={{
            padding: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Box
            component="form"
            onSubmit={handleOperatorSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Typography
              component="h2"
              variant="h4"
              sx={{ pb: 3.5 }}
            >
              Edit operator
              <Button
                color="inherit"
                variant="outlined"
                onClick={handleSettingsOpen}
                sx={{ marginTop: "4px", float: "right" }}
                startIcon={<Setings sx={{ color: "black" }} />}
              >
                JSON Settings
              </Button>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  value={operator.name}
                  error={!!error}
                  label="Name"
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  value={operator.company}
                  error={!!error}
                  label="Company"
                  onChange={handleCompanyChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  value={operator.icao_code}
                  error={!!error}
                  label="ICAO"
                  onChange={handleIcaoChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  value={operator.contact_name}
                  error={!!error}
                  label="Contact person"
                  onChange={handleContactChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  value={operator.email}
                  error={!!error}
                  label="Email"
                  onChange={handleEmailChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  value={operator.tel_no}
                  error={!!error}
                  label="Tel"
                  onChange={handleTelChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={!!operator.is_dashboard_enabled} onChange={handleDashboardEnabledChange} />}
                  label="Enable Dashboard"
                />
              </Grid>
            </Grid>
            <Typography component="p" color={RED}>
              {error}
            </Typography>
            <Typography component="p" color={GREEN}>
              {message}
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2, mt: 0 }}>
              <Grid item xs={12} md={6}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ height: "56px", width: "100%" }}
                >
                  SUBMIT
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  size="large"
                  variant="outlined"
                  sx={{ height: "56px", width: "100%" }}
                  onClick={onClose}
                >Close</Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        {isSettingsOpen && <SettingsModal settings={operator?.settings} onClose={handleSettingsClose} handleSubmit={onSettingsSubmit} />}
        <Backdrop sx={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress />
        </Backdrop>
      </Container>
    </Modal>
  );
};
