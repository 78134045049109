import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useState, ChangeEvent, SyntheticEvent } from "react";
import { API_URL } from "../config";

const postFleet = async (fleet: Fleet) => {
  const res = await fetch(`${API_URL}/fleets`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(fleet),
    credentials: "include",
  });

  if (!res.ok) {
    throw { message: res.statusText, code: res.status };
  }

  return res.json();
};

const initialLeftItems: Aircraft[] = [];
const initialRightItems: Aircraft[] = [];

export const useAddFleet = (allAircraft: Aircraft[], onClose: () => void) => {
  const [name, setName] = useState("");
  const [operator, setOperator] = useState({ id: null, label: "" });
  const [error, setError] = useState("");
  const [message, setMesssage] = useState("");
  const [leftItems, setLeftItems] = useState<Aircraft[]>(initialLeftItems);
  const [rightItems, setRightItems] = useState<Aircraft[]>(allAircraft);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(postFleet, {
    onSuccess: () => {
      queryClient.invalidateQueries('fleets');
      setError("");
      setMesssage(`Fleet ${name} has been added.`);
      onClose();
    },
    onError: (err: HttpError) => {
      if (err.code === 401) {
        localStorage.removeItem('user');
        localStorage.removeItem('isAuthenticated');
        navigate('/');
      }
      setError("You can't add this fleet");
      setMesssage("");
    },
  });

  const addItemsToCart = (items: Aircraft[], source: string) => {
    const newLeftItems =
      source === "left"
        ? leftItems.filter((aircraft) => items.findIndex((leftItem: Aircraft) => aircraft.id === leftItem.id) < 0)
        : [...leftItems, ...items];
    const newRightItems =
      source === "right"
        ? rightItems.filter((aircraft) => items.findIndex((rightItem: Aircraft) => aircraft.id === rightItem.id) < 0)
        : [...rightItems, ...items];
    setLeftItems(newLeftItems);
    setRightItems(newRightItems);
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setError("");
    setMesssage("");
  };

  const onOperatorChange = (_: SyntheticEvent<Element, Event> | null, value: { id: any, label: any } | null) => {
    setOperator({ id: value?.id || "", label: value?.label || "" });
    setError("");
    setMesssage("");
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setError("");
    setMesssage("");
    if (!name?.length) {
      setError("Please enter a valid name");
      return;
    }
    mutation.mutate({ name, operator_ref: operator.id || 0, aircraft: leftItems });
  }

  return {
    name,
    onNameChange,
    operator,
    onOperatorChange,
    addItemsToCart,
    leftItems,
    rightItems,
    onSubmit,
    status: mutation.status,
    error,
    message,
    setMesssage,
  };
};

const deleteFleet = async (id: string) => {
  const res = await fetch(`${API_URL}/fleets/${id}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

export const useDeleteFleet = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(deleteFleet, {
    onSuccess: () => {
      queryClient.invalidateQueries('fleets');
    },
    onError: (error: Error) => {
      if (error.message === '401') {
        localStorage.removeItem('fleet');
        localStorage.removeItem('isAuthenticated');
        navigate('/');
      }
    },
  });
};

const putFleet = async ({ id, name, aircraft }: Fleet) => {
  const res = await fetch(`${API_URL}/fleets/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, aircraft }),
    credentials: "include",
  });

  if (!res.ok) {
    throw { message: res.statusText, code: res.status };
  }

  return res.json();
};

export const usePutFleet = (onClose: () => void) => {
  const [fleetId, setFleetId] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [message, setMesssage] = useState("");
  const [leftItems, setLeftItems] = useState<Aircraft[]>(initialLeftItems);
  const [rightItems, setRightItems] = useState<Aircraft[]>(initialRightItems);

  const addItemsToCart = (items: Aircraft[], source: string) => {
    const newLeftItems =
      source === "left"
        ? leftItems.filter((aircraft) => items.findIndex((leftItem: Aircraft) => aircraft.id === leftItem.id) < 0)
        : [...leftItems, ...items];
    const newRightItems =
      source === "right"
        ? rightItems.filter((aircraft) => items.findIndex((rightItem: Aircraft) => aircraft.id === rightItem.id) < 0)
        : [...rightItems, ...items];
    setLeftItems(newLeftItems);
    setRightItems(newRightItems);
  };

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(putFleet, {
    onSuccess: () => {
      queryClient.invalidateQueries('fleets');
      setError("");
      setMesssage(`Fleet ${name} has been updated`);
      onClose();
    },
    onError: (err: HttpError) => {
      if (err.code === 401) {
        localStorage.removeItem('fleet');
        localStorage.removeItem('isAuthenticated');
        navigate('/');
      }

      setError("You can't update this fleet");
      setMesssage("");
    },
  });

  const setFleet = (fleet: Fleet, allAircraft: Aircraft[]) => {
    setFleetId(fleet.id || "");
    setLeftItems(fleet?.aircraft || []);
    const fleetAircraftIds = new Set(fleet?.aircraft?.map(ac => ac.id));
    const filteredAircraft = allAircraft.filter(ac => !fleetAircraftIds.has(ac.id));
    setRightItems(filteredAircraft);
    setName(fleet.name);
    setError("");
    setMesssage("");
  }

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setError("");
    setMesssage("");
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setError("");
    setMesssage("");
    if (!name?.length) {
      setError("Please enter a valid name");
      return;
    }
    mutation.mutate({ id: fleetId, name, aircraft: leftItems });
  }

  return {
    setFleet,
    name,
    onNameChange,
    addItemsToCart,
    leftItems,
    rightItems,
    onSubmit,
    status: mutation.status,
    error,
    message,
  };
};
