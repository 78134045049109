import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";

const fetchDashboard = async () => {
  const res = await fetch(`${API_URL}/dashboard`, {
    credentials: "include",
  });
  // TODO: process error
  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

const fetchDashboardFlight = async (id: string) => {
  const res = await fetch(`${API_URL}/dashboard/flight/${id}`, {
    credentials: "include",
  });
  // TODO: process error
  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

export const useDashboard = () => {
  const navigate = useNavigate();

  return useQuery(["dashboard"], () => fetchDashboard(), {
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error: Error) => {
      if (error.message === "401") {
        localStorage.removeItem("user");
        localStorage.removeItem("isAuthenticated");
        navigate("/");
      }
    },
  });
};

export const useDashboardFlight = (id: string) => {
  const navigate = useNavigate();

  return useQuery(["dashboard"], () => fetchDashboardFlight(id), {
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error: Error) => {
      if (error.message === "401") {
        localStorage.removeItem("user");
        localStorage.removeItem("isAuthenticated");
        navigate("/");
      }
    },
  });
};
