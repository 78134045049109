import { FunctionComponent } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";

import { Cell, HeadCell, GrayRow } from "..";
import { formatTime, subtractTime } from "../../utils";

type Props = Time & {
  departure_airport: string;
  arrival_airport: string;
  user_tlc: string;
};

export const TimeTable: FunctionComponent<Props> = ({
  departure_airport,
  arrival_airport,
  out_time,
  off_time,
  on_time,
  in_time,
  act_on_time,
  act_out_time,
  act_in_time,
  act_off_time,
}) => (
  <TableContainer component={Paper}>
    <Table aria-label="time table">
      <TableHead>
        <TableRow>
          <HeadCell>
            <b>Time</b>
          </HeadCell>
          <HeadCell>Schedule</HeadCell>
          <HeadCell>Actual</HeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <GrayRow>
          <Cell component="th" scope="row">
            Departure: <b>{departure_airport}</b>
          </Cell>
          <Cell>{formatTime(out_time)}</Cell>
          <Cell>{formatTime(act_out_time)}</Cell>
        </GrayRow>

        <TableRow>
          <Cell component="th" scope="row">
            Take-Off:
          </Cell>
          <Cell>{formatTime(off_time)}</Cell>
          <Cell>{formatTime(act_off_time)}</Cell>
        </TableRow>

        <TableRow>
          <Cell component="th" scope="row">
            Landing:
          </Cell>
          <Cell>{formatTime(on_time)}</Cell>
          <Cell>{formatTime(act_on_time)}</Cell>
        </TableRow>

        <GrayRow>
          <Cell component="th" scope="row">
            Arrival: <b>{arrival_airport}</b>
          </Cell>
          <Cell>{formatTime(in_time)}</Cell>
          <Cell>{formatTime(act_in_time)}</Cell>
        </GrayRow>

        <TableRow>
          <Cell component="th" scope="row">
            Flight Time:
          </Cell>
          <Cell>{subtractTime(off_time, on_time)}</Cell>
          <Cell>{subtractTime(act_off_time, act_on_time)}</Cell>
        </TableRow>

        <GrayRow>
          <Cell component="th" scope="row">
            Block Time:
          </Cell>
          <Cell>{subtractTime(out_time, in_time)}</Cell>
          <Cell>{subtractTime(act_out_time, act_in_time)}</Cell>
        </GrayRow>
      </TableBody>
    </Table>
  </TableContainer>
);
