import { FC } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { formatDateTime } from "../utils";
import { HeadCell } from ".";

type Props = {
  heading: string;
  documents: ReportDocument[];
};

export const DocumentsList: FC<Props> = ({ documents, heading }) => (
  <>
    <Typography
      component="h2"
      variant="h5"
      sx={{ pb: 2, pt: 2 }}
    >
      {heading}
    </Typography>
    {documents?.length ? <TableContainer component={Paper} sx={{ mb: 2 }}>
      <Table aria-label="Users">
        <TableHead>
          <TableRow>
            <HeadCell>Path</HeadCell>
            <HeadCell sx={{ textAlign: "right" }}>Last Edit</HeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map(({ url, modificationDate }: ReportDocument) => (
            <TableRow
              key={url}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ fontSize: 12, padding: "10px", maxWidth: 296, wordWrap: "break-word" }}>{decodeURI(url.replace('_READ.', '.'))}</TableCell>
              <TableCell sx={{ fontSize: 12, padding: "10px 10px 10px 0", textAlign: "right" }}>{formatDateTime(new Date(modificationDate * 1000))}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> : <Typography>-- No files --</Typography>}
  </>
);
