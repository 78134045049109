import { FunctionComponent } from "react";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Backdrop,
  Paper,
  CircularProgress,
} from "@mui/material";
import { StyledContainer } from "../components";
import { RED } from "../constants";
import useSignin from "../hooks/useSignin";

const Signin: FunctionComponent = () => {
  const {
    email,
    onEmailChange,
    password,
    onPasswordChange,
    handleSubmit,
    status,
    error,
  } = useSignin();

  return (
    <>
      <StyledContainer maxWidth={false}>
        <Backdrop sx={{ zIndex: 1 }} open={status === "loading"}>
          <CircularProgress />
        </Backdrop>
        <Container component="main" maxWidth="xs">
          <Paper
            sx={{
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{ fontSize: "1.8rem", padding: "10px 0 5px 0" }}
            >
              LOG IN
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                error={!!error}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="off"
                autoFocus
                value={email}
                onChange={onEmailChange}
              />
              <TextField
                error={!!error}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="off"
                value={password}
                onChange={onPasswordChange}
              />
              <Typography component="p" color={RED}>
                {error}
              </Typography>
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                sx={{ mt: 3, mb: 2, height: "50px" }}
              >
                LOG IN
              </Button>
            </Box>
          </Paper>
        </Container>
      </StyledContainer>
    </>
  );
};

export default Signin;
