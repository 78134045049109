import { useQuery } from "react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDeleteUser, useResetPassword, useResetPin } from "../hooks/useUser";
import { API_URL } from "../config";
import { EMPTY_USER } from "../constants";

const fetchUsers = async () => {
  const res = await fetch(`${API_URL}/users`, {
    credentials: "include",
  });
  // TODO: process error
  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

export const useUsers = () => {
  const [selectedUser, setSelectedUser] = useState(EMPTY_USER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [isResetPinModalOpen, setIsResetPinModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  
  const navigate = useNavigate();

  const deleteMutation = useDeleteUser();
  const resetPasswordMutation = useResetPassword();
  const resetPinMutation = useResetPin();

  const handleOpenDeleteModal = (user: User) => {
    if (user.id) {
      setSelectedUser(user);
      setIsDeleteModalOpen(true);
    }
  }

  const handleDeleteUser = () => {
    if (selectedUser.id) {
      deleteMutation.mutate(selectedUser.id);
      setIsDeleteModalOpen(false);
    }
  }

  const handleOpenEditModal = (user: User) => {
    if (user.id) {
      setSelectedUser(user);
      setIsEditModalOpen(true);
    }
  }

  const handleOpenResetPasswordModal = (user: User) => {
    if (user.id) {
      setSelectedUser(user);
      setIsResetPasswordModalOpen(true);
    }
  }

  const handleOpenResetPinModal = (user: User) => {
    if (user.id) {
      setSelectedUser(user);
      setIsResetPinModalOpen(true);
    }
  }

  const handleResetPassword = () => {
    if (selectedUser.id) {
      resetPasswordMutation.mutate(selectedUser);
      setIsResetPasswordModalOpen(false);
    }
  }

  const handleResetPin = () => {
    if (selectedUser.id) {
      resetPinMutation.mutate(selectedUser);
      setIsResetPinModalOpen(false);
    }
  }

  const users = useQuery(["users"], () => fetchUsers(), {
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error: Error) => {
      if (error.message === "401") {
        localStorage.removeItem("user");
        localStorage.removeItem("isAuthenticated");
        navigate("/");
      }
    },
  })

  return {
    users,
    setIsDeleteModalOpen,
    handleOpenDeleteModal,
    isDeleteModalOpen,
    handleDeleteUser,
    setIsEditModalOpen,
    handleOpenEditModal,
    isEditModalOpen,
    setIsResetPasswordModalOpen,
    handleOpenResetPasswordModal,
    isResetPasswordModalOpen,
    handleResetPassword,
    setIsResetPinModalOpen,
    handleOpenResetPinModal,
    isResetPinModalOpen,
    handleResetPin,
    selectedUser,
  };
};

const fetchOperators = async () => {
  const res = await fetch(`${API_URL}/operators`, {
    credentials: "include",
  });
  // TODO: process error
  if (!res.ok) {
    throw new Error(`${res.status}`);
  }

  return res.json();
};

export const useOperators = () => {
  const navigate = useNavigate();

  return useQuery(["operators"], () => fetchOperators(), {
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error: Error) => {
      if (error.message === "401") {
        localStorage.removeItem("user");
        localStorage.removeItem("isAuthenticated");
        navigate("/");
      }
    },
  });
};
