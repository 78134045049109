import { FunctionComponent } from "react";
import { Container, Backdrop, CircularProgress, Box } from "@mui/material";
import { WhiteBackground } from "../components";
import { useDashboard } from "../hooks/useDashboard";

const Dashboard: FunctionComponent = () => {
  const { data, isLoading, isSuccess } = useDashboard();
  const height = 800;

  return (
    <WhiteBackground>
      <Container>
        <Backdrop sx={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress />
        </Backdrop>
        {!!isSuccess && data?.url && <Box sx={{ height: height - 61, overflow: "hidden" }}>
          <iframe
            src={`${data.url}#bordered=false&titled=false`}
            width="100%"
            height={height}
          />
        </Box>}
      </Container>
    </WhiteBackground>
  )
};

export default Dashboard;
