import { FunctionComponent } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Navigation, GlobalStyles, theme } from "./components";
import Home from "./pages/Home";
import Flights from "./pages/Flights";
import Flight from "./pages/Flight";
import ResetPassword from "./pages/ResetPassword";
import AdminUsers from "./pages/AdminUsers";
import Users from "./pages/Users";
import Dashboard from "./pages/Dashboard";
import DashboardFlight from "./pages/DashboardFlight";
import Fleets from "./pages/Fleets";
import Operators from "./pages/Operators";
import LibraryReports from "./pages/LibraryReports";

const queryClient = new QueryClient();

const App: FunctionComponent = () => {
  const isAdmin = localStorage.getItem('user')?.includes(`"role":"admin"`);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Navigation />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/flights" element={<Flights />} />
              <Route path="/flight/:id" element={<Flight />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="/reset-pin/:token" element={<ResetPassword />} />
              <Route path="/users" element={isAdmin ? <AdminUsers /> : <Users />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/flight/:id" element={<DashboardFlight />} />
              <Route path="/fleets" element={<Fleets />} />
              <Route path="/operators" element={<Operators />} />
              <Route path="/users/:id/library/report" element={<LibraryReports />} />
            </Routes>
          </LocalizationProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
