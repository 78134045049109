import { FunctionComponent } from "react";
import { dateToHHMM } from "../utils";

type Props = {
  waypointName: string;
  ETO: string;
  ATO: string;
  MFQ: number;
  AFQ: number;
  EFQ: number;
};

export const Tooltip: FunctionComponent<Props> = ({
  waypointName,
  ETO,
  ATO,
  MFQ,
  AFQ,
  EFQ,
}) => (
  <div>
    <span>
      <b>Waypoint:</b> {waypointName}
    </span>
    <div>
      <b>ETO:</b> {dateToHHMM(ETO)}
    </div>
    <div>
      <b>ATO:</b> {ATO ? dateToHHMM(ATO) : "-"}
    </div>
    <div>
      <b>Min Fuel:</b> {MFQ}
    </div>
    <div>
      <b>Fuel On Board:</b> {AFQ || "-"}
    </div>
    <div>
      <b>Planned Fuel:</b> {EFQ}
    </div>
  </div>
);
